import Header from "./views/Header";
import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import Footer from "./views/Footer";
const Home = lazy(() => import("./components/Home"));
const Aboutus = lazy(() => import("./components/Aboutus"));
const Training = lazy(() => import("./components/Training"));
const Workshop = lazy(() => import("./components/Workshop"));
const ITServices = lazy(() => import("./components/ITServices"));
const Event = lazy(() => import("./components/Event"));
const Career = lazy(() => import("./components/Career"));
const Blog = lazy(() => import("./components/Blog"));
const Register = lazy(() => import("./components/Register"));
const Contactus = lazy(() => import("./components/Contactus"));
const Verification = lazy(() => import("./components/Verification"));
const WorkshopDetails = lazy(() => import("./components/Workshopdetails"));
const EventDetails = lazy(() => import("./components/EventDetails"));
const Upevents = lazy(() => import("./components/Upevents"));
const BlogDetails = lazy(() => import("./components/Blogdetails"));
const Team = lazy(() => import("./components/Team"));
function App() {
  return (
    <>
      <Header />
      <Suspense fallback="">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<Aboutus />} />
          <Route path="/training/*" element={<Training />} />
          <Route path="/workshop" element={<Workshop />} />
          <Route path="/services/*" element={<ITServices />} />
          <Route path="/events" element={<Event />} />
          <Route path="/career" element={<Career />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/blog_details/:id" element={<BlogDetails />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/workshop_details/:id" element={<WorkshopDetails />} />
          <Route path="/event_details/:id" element={<EventDetails />} />
          <Route path="/upevents" element={<Upevents />} />
          <Route path="/team" element={<Team />} />
        </Routes>
      </Suspense>
      <Footer />
    </>
  );
}

export default App;
