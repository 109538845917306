import footerLogo from "../images/logo2.png";
import { NavLink } from "react-router-dom";
import iso from '../images/iso.webp';
const Footer = () => {
  const d = new Date();
  let year = d.getFullYear();
  return (
    <>
      <footer>
        <div class="container" style={{position:'relative'}}>
          <div className="aaa">
            <img src={iso}/>
          </div>
          <div class="footer_top">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-6">
                <div class="footer_single_col footer_intro">
                  <img src={footerLogo} alt="" class="f_logo" />
                  <p>
                    Demmisto Technologies is a leading cybersecurity firm
                    specializing in IT Training, Security Auditing, Consulting,
                    and Outsourcing services. Recognized for rapid growth, we
                    provide cutting-edge solutions, empowering clients to
                    navigate the complex digital realm with confidence. Our
                    commitment to excellence sets us apart in delivering
                    top-tier cybersecurity services. The top cyber security
                    company in India, Demmisto Technologies, operates under the
                    guiding principle "Your security is our priority."
                  </p>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-2">
                <div class="footer_single_col">
                  <h3>Useful Links</h3>
                  <ul class="location_info quick_inf0">
                    <li>
                      <NavLink to="about">About Us</NavLink>
                    </li>
                    <li>
                    <NavLink to="contact-us">Contact Us</NavLink>
                    </li>
                    <li>
                    <NavLink to="career">Career</NavLink>
                    </li>
                    <li>
                    <NavLink to="events">Events</NavLink>
                    </li>
                    <li>
                    <NavLink to="workshop">Workshop</NavLink>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <div class="footer_single_col contact">
                  <h3>Contact Us</h3>
                  {/* <p>Fell free to get in touch us via Phone or send us a message.</p> */}
                  <div class="contact_info">
                    <p>
                      <i class="flaticon-maps-and-flags"></i>&nbsp;&nbsp;401,
                      4th Floor, Gala Empire, Opp Doordarshan Tower, Drive-in
                      Road, Thaltej, Ahmedabad - 380052, Gujarat, India
                    </p>
                    <p>
                      <i class="flaticon-calendar"></i>&nbsp;&nbsp;Mon-Sun 9:00
                      AM - 8:00 PM
                    </p>
                    <p>
                      <i class="flaticon-phone-receiver"></i>&nbsp;&nbsp;+91 -
                      84205 55755 / 079- 40059199
                    </p>
                    <p class="email">
                      <i class="flaticon-mail-black-envelope-symbol"></i>
                      &nbsp;&nbsp;contact@demmistotechnologies.com
                    </p>
                  </div>
                  <ul class="social_items d-flex list-unstyled">
                    <li>
                      <a href="https://www.facebook.com/demmistotechnologies">
                        <i class="fab fa-facebook-f fb-icon"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/demmistotech">
                        <i class="fab fa-twitter twitt-icon"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/demmisto-technologies/">
                        <i class="fab fa-linkedin-in link-icon"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/demmisto_technologies">
                        <i class="fab fa-instagram ins-icon"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="round_shape">
          <span class="shape_1"></span>
          <span class="shape_2"></span>
          <span class="shape_3"></span>
          <span class="shape_4"></span>
          <span class="shape_5"></span>
          <span class="shape_6"></span>
        </div>
        <img
          src="images/shapes/footer_bg_shpe_1.png"
          alt=""
          class="shapes1_footer"
        />
      </footer>
      <div className="footer_copy">
        <p>Demmisto Technologies © {year}. All rights reserved.</p>
      </div>
      <section id="scroll-top" class="scroll-top">
        <h2 class="disabled">Scroll to top</h2>
        <div class="to-top pos-rtive">
          <a href="#">
            <i class="flaticon-right-arrow"></i>
          </a>
        </div>
      </section>
    </>
  );
};

export default Footer;
